<template>
<b-container fluid>
  <bo-page-title />

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col lg="5">
          <h5 class="card-title">{{ pageTitle }}</h5>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="Select Status" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.status" :options="mrStatus"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
         <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(tbt_vehicle)="data">
          <div>{{ data.value }}</div>
        </template>
        <template #cell(tbt_start_time)="data">
          {{ timeOfUse(data.item.tbt_start_time, data.item.tbt_end_time) }}
        </template>
        <template
          #cell(tbt_status)="data"
        >
          <b-badge
            v-if="data.value=='pending'"
            variant="info"
          >Pending</b-badge>
          <b-badge
            v-else-if="data.value == 'reject'"
            variant="danger"
          >Reject</b-badge>
          <b-badge v-else-if="data.value == 'approve'" variant="success">
            Approve
          </b-badge>
        </template>
        <template
          #cell(action)="data"
        >
          <router-link
            v-if="moduleRole('edit')"
            v-b-tooltip.hover
            title="Detail"
            class="icon_action"
            :to="{name: $route.name, params: {pageSlug: data.item.tbt_book_code} }"
          >
            <i class="ti-eye"></i>
          </router-link>
          <a
            v-if="moduleRole('delete')"
            v-b-tooltip.hover
            title="Delete"
            class="icon_action"
            @click="doDelete(data.index, data.item)"
            style="cursor:pointer;"
          >
            <i class="ti-trash"></i>
          </a>

          <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage" class="mb-0 mr-2">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage" class="mb-0 ml-2">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>
  </b-card>

  <template v-else>
    <Form :row.sync="row" :Config="Config" :mrVehicle="mrVehicle" :mrValidation="mrValidation"/>
  </template>
  <div class="media-overlay" :class="showImage && detailImg ? 'show' : ''" @click.stop="showImage = false">
    <div class="media-content" @click.stop>
      <div class="media-dialog parent-wrapper">
        <b-img :src="uploader(detailImg)" />
        <b-button size="sm" @click.stop="showImage = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
      </div>
    </div>
  </div>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'tbt_id',
      statusKey:'tbt_status',
      mrValidation: {},
      showImage: false,
      mrVehicle: [],
      mrStatus: [],
      detailImg: '',
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'tbt_book_code', label: 'Booking Code',
        },
        {
          key: 'tbt_person', label: 'Name',
        },
        {
          key: 'tbt_start_time', label: 'Time of Use',
        },
        {
          key: 'tbt_vehicle', label: 'Vehicle',
        },
        {
          key: 'tbt_status', label: 'Status',
        },
        {
          key: 'action', 
        },
      ],
    }
  },
  mounted(){
    this.apiGet()
    this.filter.status = this.$route.query.status || 'pending'
  },
  methods: {
    setLightBox(src){
      this.detailImg = src
      this.showImage = true
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'filter'(){
      if('status' in this.filter == false){
        this.$set(this.filter, 'status', 'pending')
      }
    }
  }
}
</script>