<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg=4>
                <b-form-group>
                  <label>Generate Booking on</label>
                  <p v-if="$parent.isAdd && dateNow">{{ dateNow | moment('LLL') }}</p>
                  <p v-else>
                    <template v-if="row.tbt_date">
                      {{ row.tbt_date | moment('LLL') }}
                    </template>
                    </p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <label>Name <span class="text-danger mr5" v-if="$parent.isAdd">*</span></label>
                  <vue-typeahead-bootstrap
                    v-model="row.tbt_person"
                    :data="dataPerson"
                    @input="search"
                    v-if="$parent.isAdd"
                  />
                  <p v-else>{{ row.tbt_person }}</p>
                  <VValidate name="Name" v-model="row.tbt_person" :rules="mrValidation.tbt_person" />
                  <b-form-checkbox
                    v-if="$parent.isAdd"
                    id="checkbox-1"
                    v-model="row.save_name_note"
                    name="checkbox-1"
                    class="mt-1"
                    value="Y"
                    unchecked-value="N"
                  >
                    Masukkan Catatan
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col lg=4>
                <b-form-group>
                  <label>Vehicle <span class="text-danger mr5" v-if="$parent.isAdd">*</span></label>
                  <v-select 
                    placeholer="Vehicle"
                    v-if="$parent.isAdd"
                    v-model="row.tbt_vehicle" 
                    :options="mrVehicle"
                    :reduce="data => data.value" 
                    />
                    <p v-else>{{ row.vehicle }}</p>
                  <VValidate name="Vehicle Category" v-model="row.tbt_vehicle" :rules="mrValidation.tbt_vehicle" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!$parent.isAdd">
              <b-col md=4>
                <b-form-group>
                  <label>Status</label>
                  <div>
                    <b-badge v-if="row.tbt_status == 'pending'" variant="info">Pending</b-badge>
                    <b-badge v-else-if="row.tbt_status == 'approve'" variant="success">Approved</b-badge>
                    <b-badge v-else-if="row.tbt_status == 'reject'" variant="danger">Reject</b-badge>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md=4 v-if="row.tbt_status == 'reject'">
                <label>Reject Reason</label>
                <p>{{ row.tbt_reject_reason }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col md=12>
                <div class="panel">
                  <div class="panel_title">Usage Plan</div>
                  <div class="panel_body">
                    <b-form-row>
                      <b-col lg=3>
                        <b-form-group>
                          <label>Estimate Start Time <span class="text-danger mr5" v-if="$parent.isAdd">*</span></label>
                          <date-picker v-if="$parent.isAdd" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm"  prefix-class="crm" v-model="row.tbt_start_time" :disabled-date="$parent.beforeToday" />
                          <p v-else>
                            <template v-if="row.tbt_start_time">
                              {{ row.tbt_start_time | moment('lll') }}
                            </template>
                          </p>
                          <VValidate name="Estimate Start Time" :rules="mrValidation.tbt_start_time" v-model="row.tbt_start_time" />
                        </b-form-group>
                      </b-col>
                      <b-col lg=3>
                        <b-form-group>
                          <label>Estimate Finish Time <span class="text-danger mr5" v-if="$parent.isAdd">*</span></label>
                          <date-picker v-if="$parent.isAdd" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm"  prefix-class="crm" v-model="row.tbt_end_time" :disabled-date="beforeStart" :disabled-time="beforeTime"/>
                          <p v-else>{{ row.tbt_end_time | moment('lll') }}</p>
                          <VValidate name="Estimate Finish Time" :rules="mrValidation.tbt_end_time" v-model="row.tbt_end_time" />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col md=2>
                        <p>Destinations</p>
                      </b-col>
                      <div class="w-100"></div>
                      <b-col md=12 v-for="(data, index) in row.tbt_destination" :key="index">
                        <b-row :class=" index % 2 == 0 ? 'bg-light' : ''">
                          <b-col lg="5">
                            <b-form-group>
                              <label>PIC<span class="text-danger mr5" v-if="$parent.isAdd">*</span></label>
                              <vue-typeahead-bootstrap
                                v-model="data.pic"
                                :data="dataDestination"
                                :serializer="item => item.mld_pic"
                                :inputName="'PIC'+index"
                                @input="searchDestination"
                                @hit="updateDestination($event, data)"
                                v-if="$parent.isAdd"
                              >
                                <template slot="suggestion" slot-scope="{data, htmlText}">
                                  <span v-html="htmlText"></span><br />
                                  <small>{{ data.mld_address }}</small>
                                </template>
                              </vue-typeahead-bootstrap>
                              <p v-else>{{ data.pic }}</p>
                              <VValidate name="PIC" v-model="data.pic" rules="required|min:3" />
                              <b-form-checkbox
                                :id="'checkbox'+index"
                                v-model="data.save_dest_note"
                                :name="'checkbox'+index"
                                class="mt-1"
                                value="Y"
                                v-if="$parent.isAdd"
                                unchecked-value="N"
                              >
                                Masukkan Catatan
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                          <div class="w-100"></div>
                          <b-col lg="5">
                            <b-form-group>
                              <label>Address <span class="text-danger mr5" v-if="$parent.isAdd">*</span></label>
                              <b-form-textarea :rows="4" v-if="$parent.isAdd" v-model="data.destination" placeholder="e.g. JL. Raya Puspitek, Setu Tangerang Selatan"></b-form-textarea>
                              <p v-else>{{ data.destination }}</p>
                              <VValidate name="Address" v-model="data.destination" rules="required|min:3" />
                            </b-form-group>
                          </b-col>
                          <div class="w-100"></div>
                          <b-col lg="5">
                            <b-form-group>
                              <label>Purposes <span class="text-danger mr5" v-if="$parent.isAdd">*</span></label>
                              <b-form-textarea :rows="2" v-if="$parent.isAdd" v-model="data.purpose" placeholder="e.g. Survey Progress"></b-form-textarea>
                              <p v-else>{{ data.purpose }}</p>
                              <VValidate name="Purpose" v-model="data.purpose" rules="required|min:3" />
                            </b-form-group>
                          </b-col>
                          <b-col lg="2" class="align-self-end">
                            <b-form-group>
                              <b-button 
                                v-if="data.onEdit && $parent.isAdd"
                                variant="danger"
                                @click="removeDestination(data)"
                                href="javascript:;"
                                v-b-tooltip.hover.noninteractive="'Remove'" 
                                class="btn-icon">
                                <i class="ti-trash"></i>
                                </b-button>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="$parent.isAdd">
                      <b-col md=9 class="text-center">
                        <b-button variant="outline-info" class="btn-rounded"
                          @click="row.tbt_destination.push({ id: row.tbt_destination.length + 1, onEdit: true, new: true, pic: '' })">
                          <i class="fas fa-plus-circle mr-1"></i>
                          Add Destination
                        </b-button>
                      </b-col>
                    </b-form-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">{{$parent.isAdd ? 'Cancel' : 'Back'}}</button>
                <b-button v-if="$parent.isAdd" type="submit" variant="info" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'

 export default {
    props: {
      row: Object,
      mrVehicle: Array,
      mrValidation: Object,
      Config: Object,
    },
    data(){
      return {
        mrType: [
          {label: "Staff", value: "S"},
          {label: "Partner", value: "P"}
        ],
        dateNow: new Date(),
        timeout: null,
        dataPerson: [],
        dataDestination: []
      }
    },
    mounted(){
      this.updateTime()
    },
    methods: {
      updatePic(event, data){
        console.log(event)
        this.$set(data, 'pic', event)
      },  
      updatePerson(input){
        this.$set(this.row, 'tbt_person', input)
      },
      updateDestination(input, data){
        this.$set(data, 'destination', input.mld_address)
      },
      getResultValue(res){
        return res
      },
      getResultDestValue(res){
        return res.mld_pic
      },
      search(){
        clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            Gen.apiRest("/get/"+this.$parent.modulePage + '/search-person', {
              params: {q: this.row.tbt_person}
            }).then(res => {
              this.dataPerson = res.data
            })
          }, 400) 
      },
      searchDestination(event){
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          Gen.apiRest("/get/"+this.$parent.modulePage + '/search-destination', {
            params: {q: event}
          }).then(res => {
            this.dataDestination = res.data
          })
        }, 400)
      },
      beforeStart(date){
        // return date ? false : false
        return new Date(date).setHours(23, 59, 59) <= new Date(this.row.tbt_start_time).setHours(0, 0, 0)
      },
      removeDestination(data){
        this.row.tbt_destination = this.row.tbt_destination.filter(v => !v.id || v.id != data.id)
      },
      updateTime(){
        setTimeout( () => {
          this.dateNow = new Date()
        },1000)
      },
      beforeTime(date){
        // return date ? false : false
        return date <= new Date(this.row.tbt_start_time)
      },
    }
 }
</script>
